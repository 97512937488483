<template>
  <div class="secondary--text filter-options filter-options--campaign-planning">
    <v-row>
      <v-col
        v-for="item in filters"
        :key="item"
        cols="6"
        sm="4"
        md="3"
        lg="2"
      >
        <component
          :is="item"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const FilterLocation = () => import(/* webpackChunkName: "filter-location-campaign-planning" */ "@/blocks/campaign-planning/FilterLocation.vue")
const FilterLanguage = () => import(/* webpackChunkName: "filter-language-campaign-planning" */ "@/blocks/campaign-planning/FilterLanguage.vue")
const FilterInterest = () => import(/* webpackChunkName: "filter-interest-campaign-planning" */ "@/blocks/campaign-planning/FilterInterest.vue")
const FilterGender = () => import(/* webpackChunkName: "filter-gender-campaign-planning" */ "@/blocks/campaign-planning/FilterGender.vue")
// const FilterCategory = () => import(/* webpackChunkName: "filter-category-campaign-planning" */ "@/blocks/campaign-planning/FilterCategory.vue")
const FilterFollowers = () => import(/* webpackChunkName: "filter-followers-campaign-planning" */ "@/blocks/campaign-planning/FilterFollowers.vue")
const FilterEngagements = () => import(/* webpackChunkName: "filter-engagements-campaign-planning" */ "@/blocks/campaign-planning/FilterEngagements.vue")

export default {
  name: "FilterOptions",

  components: {
    FilterLocation,
    FilterLanguage,
    FilterInterest,
    FilterGender,
    // FilterCategory,
    FilterFollowers,
    FilterEngagements
  },

  data: () => ({
    filters: [
      "filter-location",
      "filter-language",
      "filter-interest",
      "filter-gender",
      // "filter-category",
      "filter-followers",
      "filter-engagements"
    ]
  })
}
</script>

<style lang="stylus">
.filter-options--campaign-planning
  .v-icon
    color var(--v-secondary) !important

  .filter-title
    color #4c4c4c !important
</style>
